import {
  Box,
  Button,
  Container,
  Heading,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { getSharedPageProps } from 'components/Layout';
import { HeadMeta } from 'components/HeadMeta';
import { colors } from 'constants/constants';
import { GetStaticProps } from 'next';
import Link from 'next/link';
import React from 'react';

export default function Custom404(): JSX.Element {
  return (
    <>
      <HeadMeta nodeName="Sidan kan inte hittas" />
      <Box p={{ base: '2em 5%', md: '5em 5%', xl: '5em 0' }}>
        <Container maxW="7xl">
          <Box maxW="50%" margin={'0 auto'} textAlign="center">
            <Heading as="h1" fontSize={80} pb={12}>
              404
            </Heading>
            <Text color={colors.neutrals[700]} pb={12}>
              Vi har många förråd men det här har vi inte hört talas om.
            </Text>
            <SimpleGrid
              margin="0 auto"
              columns={{ base: 1, md: 2, lg: 2, xl: 2 }}
              spacing={10}>
              <Link href="/">
                <a>
                  <Button margin="0 auto" variant="outline" width="100%">
                    Till startsidan
                  </Button>
                </a>
              </Link>
              <Link href="/kontakt">
                <a>
                  <Button margin="0 auto" variant="outline" width="100%">
                    Kontakta oss
                  </Button>
                </a>
              </Link>
            </SimpleGrid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  const sharedPageProps = await getSharedPageProps();

  return {
    props: { ...sharedPageProps },
  };
};
